<div class="section mt-3">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title mb-3">{{ "OUR_CLIENTS.TITLE" | translate }}</h1>
            <p class="section-desc text-muted">{{ "OUR_CLIENTS.DESC" | translate }}</p>
  
            <div class="awards-carousel"> 
                <div class="swiper-container h-100" [swiper]="config"> 
                    <div class="swiper-wrapper h-100">      
                        <div *ngFor="let award of awards" class="swiper-slide">
                            <div class="award-item"> 
                                <img [attr.data-src]="award.image" class="swiper-lazy"> 
                                <div class="swiper-lazy-preloader"></div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
                
        </div>
    </div>   
  </div>
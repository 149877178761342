<span fxLayoutAlign="center center" class="item" (click)="makeCall()">
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">call</mat-icon>
  <span class="px-1">{{ "FOOTER.PHONE" | translate }}</span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-xs></span>
<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-xs>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">location_on</mat-icon>
  <span class="px-1">{{ "FOOTER.ADDRESS" | translate }}</span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-sm></span>
<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-sm>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">mail</mat-icon>
  <span class="px-1">{{ "FOOTER.E-MAIL" | translate }}</span>
</span>

<div *ngIf="menuItems.length" class="menu-items-carousel">   
    <div class="swiper-container h-100 carousel-outer" > 
        <div class="swiper-wrapper h-100">      
            <div *ngFor="let menuItem of menuItems" class="swiper-slide">
                <<mat-card fxFlex="33" fxFlex.xs="100" class="menu-item p-0" >   
                    <div fxFlex="100" [fxShrink]="(viewType == 'grid') ? '3' : '2'" class="thumbnail-section">
                        <mat-chip-listbox *ngIf="menuItem.discount">
                            <mat-chip-option color="warn" highlighted selectable="false">{{menuItem.discount}}% OFF</mat-chip-option>
                        </mat-chip-listbox> 
                
                    </div>  
                    <div fxFlex="100" class="p-3"> 
                        <div fxLayout="column" fxLayoutAlign="start stretch" class="h-100">
                            <mat-card-content class="mb-0 p-0">
                                <h1 class="title"><a [routerLink]="['/menu', menuItem.id]">{{menuItem.name}}</a></h1>   
                                <div class="mt-2"> 
                                    <p>{{menuItem.description}}</p> 
                                    <div *ngIf="viewType == 'list'" fxShow="false" fxShow.gt-sm="true">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis magnam illum, suscipit nostrum ea quidem. Architecto quisquam eligendi tempora, obcaecati delectus, odio natus suscipit vel est temporibus error officia esse!</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis magnam illum, suscipit nostrum ea quidem. Architecto quisquam eligendi tempora, obcaecati delectus, odio natus suscipit vel est temporibus error officia esse!</p>
                                    </div>  
                                </div>  
                            </mat-card-content>
                            <div class="sticky-footer-helper" fxFlex="grow"></div>
                            <div class="m-0 p-0">
                                <div class="features">     
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <mat-icon color="primary" class="mat-icon-sm">local_offer</mat-icon>
                                        <span class="px-1">Category:</span>
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ ( appService.Data.categories | filterById : menuItem.categoryId )?.name }}</span>
                                    </p>
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <mat-icon color="primary" class="mat-icon-sm">{{ (menuItem.availibilityCount > 0) ? 'check_circle' : 'unpublished'}}</mat-icon>
                                        <span class="px-1">Availibility:</span>
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ (menuItem.availibilityCount > 0) ? 'Available':'Unavailable'}}</span>
                                    </p>
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <span fxLayout="row" fxLayoutAlign="start center">
                                            <span class="mat-icon mat-icon-sm primary-color">
                                                <svg viewBox="0 0 24 24">
                                                    <path d="M12,3C10.73,3 9.6,3.8 9.18,5H3V7H4.95L2,14C1.53,16 3,17 5.5,17C8,17 9.56,16 9,14L6.05,7H9.17C9.5,7.85 10.15,8.5 11,8.83V20H2V22H22V20H13V8.82C13.85,8.5 14.5,7.85 14.82,7H17.95L15,14C14.53,16 16,17 18.5,17C21,17 22.56,16 22,14L19.05,7H21V5H14.83C14.4,3.8 13.27,3 12,3M12,5A1,1 0 0,1 13,6A1,1 0 0,1 12,7A1,1 0 0,1 11,6A1,1 0 0,1 12,5M5.5,10.25L7,14H4L5.5,10.25M18.5,10.25L20,14H17L18.5,10.25Z" />
                                                </svg>
                                            </span> 
                                            <span class="px-1">Weight:</span>
                                        </span>   
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ menuItem.weight }} g</span>
                                    </p>
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <span fxLayout="row" fxLayoutAlign="start center"> 
                                            <span class="mat-icon mat-icon-sm primary-color">
                                                <svg viewBox="0 0 24 24">
                                                    <path *ngIf="!menuItem.isVegetarian" d="M20.84 22.73L15.14 17.03C13.26 18.79 10.92 20 8 20C7.64 20 7.14 19.87 6.66 19.7L5.71 22L3.82 21.34C5.15 18.03 6.5 14.32 9.66 11.55L8.77 10.66C6.76 12.03 4.86 14.1 3.75 17.25C3.75 17.25 2 15.5 2 13.5C2 12 3.12 9.32 5.72 7.61L1.11 3L2.39 1.73C2.39 1.73 16.39 15.74 16.39 15.74L22.11 21.46L20.84 22.73M17 8C15.35 8.37 13.93 8.88 12.7 9.5L17.5 14.29C20.87 9.35 22 3 22 3C21.03 4.95 14.35 5.24 9.38 6.18L12.15 8.95C14.81 8 17 8 17 8Z" />
                                                    <path *ngIf="menuItem.isVegetarian"  d="M17,8C8,10 5.9,16.17 3.82,21.34L5.71,22L6.66,19.7C7.14,19.87 7.64,20 8,20C19,20 22,3 22,3C21,5 14,5.25 9,6.25C4,7.25 2,11.5 2,13.5C2,15.5 3.75,17.25 3.75,17.25C7,8 17,8 17,8Z" />
                                                </svg>
                                            </span> 
                                        </span>  
                                        <span class="px-1">Vegetarian:</span>
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ (menuItem.isVegetarian) ? 'Yes':'No'}}</span>
                                    </p>  
                                </div> 
                
                                <div fxLayout="row" [fxLayoutAlign]="(viewType == 'grid') ? 'space-between center' : 'end center'" class="mt-3">
                                    <span class="text-muted">Customer Review<span *ngIf="viewType == 'list'" class="px-2">:</span></span>
                                    <app-rating [ratingsCount]="menuItem?.ratingsCount" [ratingsValue]="menuItem?.ratingsValue"></app-rating>
                                </div>  
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1"> 
                
                                    <h2 *ngIf="!menuItem.discount" class="primary-color"> 
                                        {{menuItem.price | currency : 'USD' : 'symbol' : '1.2-2'}}  
                                    </h2> 
                                    <h2 *ngIf="menuItem.discount">  
                                        <span class="text-muted old-price">{{menuItem.price | currency : 'USD' : 'symbol' : '1.2-2'}}</span> 
                                        <span class="primary-color mx-2">{{ (menuItem.price - (menuItem.price * (menuItem.discount / 100))) | currency : 'USD' : 'symbol' : '1.2-2'}}</span>
                                    </h2> 
                
                
                                    <div class="text-muted">  
                                        <button mat-icon-button [disabled]="onFavorites()" (click)="addToFavorites()" matTooltip="Add To Favorite">
                                            <mat-icon>favorite_border</mat-icon>
                                        </button>
                                        <button mat-icon-button [disabled]="onCart()" (click)="addToCart()" matTooltip="Add To Cart">
                                            <mat-icon>shopping_cart</mat-icon>
                                        </button>  
                                    </div> 
                                </div> 
                            </div> 
                        </div>   
                    </div>  
                </mat-card>
                <mat-card fxFlex="33" fxFlex.xs="100" class="menu-item p-0" >   
                    <div fxFlex="100" [fxShrink]="(viewType == 'grid') ? '3' : '2'" class="thumbnail-section">
                        <mat-chip-listbox *ngIf="menuItem.discount">
                            <mat-chip-option color="warn" highlighted selectable="false">{{menuItem.discount}}% OFF</mat-chip-option>
                        </mat-chip-listbox> 
                
                    </div>  
                    <div fxFlex="100" class="p-3"> 
                        <div fxLayout="column" fxLayoutAlign="start stretch" class="h-100">
                            <mat-card-content class="mb-0 p-0">
                                <h1 class="title"><a [routerLink]="['/menu', menuItem.id]">{{menuItem.name}}</a></h1>   
                                <div class="mt-2"> 
                                    <p>{{menuItem.description}}</p> 
                                    <div *ngIf="viewType == 'list'" fxShow="false" fxShow.gt-sm="true">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis magnam illum, suscipit nostrum ea quidem. Architecto quisquam eligendi tempora, obcaecati delectus, odio natus suscipit vel est temporibus error officia esse!</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis magnam illum, suscipit nostrum ea quidem. Architecto quisquam eligendi tempora, obcaecati delectus, odio natus suscipit vel est temporibus error officia esse!</p>
                                    </div>  
                                </div>  
                            </mat-card-content>
                            <div class="sticky-footer-helper" fxFlex="grow"></div>
                            <div class="m-0 p-0">
                                <div class="features">     
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <mat-icon color="primary" class="mat-icon-sm">local_offer</mat-icon>
                                        <span class="px-1">Category:</span>
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ ( appService.Data.categories | filterById : menuItem.categoryId )?.name }}</span>
                                    </p>
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <mat-icon color="primary" class="mat-icon-sm">{{ (menuItem.availibilityCount > 0) ? 'check_circle' : 'unpublished'}}</mat-icon>
                                        <span class="px-1">Availibility:</span>
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ (menuItem.availibilityCount > 0) ? 'Available':'Unavailable'}}</span>
                                    </p>
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <span fxLayout="row" fxLayoutAlign="start center">
                                            <span class="mat-icon mat-icon-sm primary-color">
                                                <svg viewBox="0 0 24 24">
                                                    <path d="M12,3C10.73,3 9.6,3.8 9.18,5H3V7H4.95L2,14C1.53,16 3,17 5.5,17C8,17 9.56,16 9,14L6.05,7H9.17C9.5,7.85 10.15,8.5 11,8.83V20H2V22H22V20H13V8.82C13.85,8.5 14.5,7.85 14.82,7H17.95L15,14C14.53,16 16,17 18.5,17C21,17 22.56,16 22,14L19.05,7H21V5H14.83C14.4,3.8 13.27,3 12,3M12,5A1,1 0 0,1 13,6A1,1 0 0,1 12,7A1,1 0 0,1 11,6A1,1 0 0,1 12,5M5.5,10.25L7,14H4L5.5,10.25M18.5,10.25L20,14H17L18.5,10.25Z" />
                                                </svg>
                                            </span> 
                                            <span class="px-1">Weight:</span>
                                        </span>   
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ menuItem.weight }} g</span>
                                    </p>
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <span fxLayout="row" fxLayoutAlign="start center"> 
                                            <span class="mat-icon mat-icon-sm primary-color">
                                                <svg viewBox="0 0 24 24">
                                                    <path *ngIf="!menuItem.isVegetarian" d="M20.84 22.73L15.14 17.03C13.26 18.79 10.92 20 8 20C7.64 20 7.14 19.87 6.66 19.7L5.71 22L3.82 21.34C5.15 18.03 6.5 14.32 9.66 11.55L8.77 10.66C6.76 12.03 4.86 14.1 3.75 17.25C3.75 17.25 2 15.5 2 13.5C2 12 3.12 9.32 5.72 7.61L1.11 3L2.39 1.73C2.39 1.73 16.39 15.74 16.39 15.74L22.11 21.46L20.84 22.73M17 8C15.35 8.37 13.93 8.88 12.7 9.5L17.5 14.29C20.87 9.35 22 3 22 3C21.03 4.95 14.35 5.24 9.38 6.18L12.15 8.95C14.81 8 17 8 17 8Z" />
                                                    <path *ngIf="menuItem.isVegetarian"  d="M17,8C8,10 5.9,16.17 3.82,21.34L5.71,22L6.66,19.7C7.14,19.87 7.64,20 8,20C19,20 22,3 22,3C21,5 14,5.25 9,6.25C4,7.25 2,11.5 2,13.5C2,15.5 3.75,17.25 3.75,17.25C7,8 17,8 17,8Z" />
                                                </svg>
                                            </span> 
                                        </span>  
                                        <span class="px-1">Vegetarian:</span>
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ (menuItem.isVegetarian) ? 'Yes':'No'}}</span>
                                    </p>  
                                </div> 
                
                                <div fxLayout="row" [fxLayoutAlign]="(viewType == 'grid') ? 'space-between center' : 'end center'" class="mt-3">
                                    <span class="text-muted">Customer Review<span *ngIf="viewType == 'list'" class="px-2">:</span></span>
                                    <app-rating [ratingsCount]="menuItem?.ratingsCount" [ratingsValue]="menuItem?.ratingsValue"></app-rating>
                                </div>  
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1"> 
                
                                    <h2 *ngIf="!menuItem.discount" class="primary-color"> 
                                        {{menuItem.price | currency : 'USD' : 'symbol' : '1.2-2'}}  
                                    </h2> 
                                    <h2 *ngIf="menuItem.discount">  
                                        <span class="text-muted old-price">{{menuItem.price | currency : 'USD' : 'symbol' : '1.2-2'}}</span> 
                                        <span class="primary-color mx-2">{{ (menuItem.price - (menuItem.price * (menuItem.discount / 100))) | currency : 'USD' : 'symbol' : '1.2-2'}}</span>
                                    </h2> 
                
                
                                    <div class="text-muted">  
                                        <button mat-icon-button [disabled]="onFavorites()" (click)="addToFavorites()" matTooltip="Add To Favorite">
                                            <mat-icon>favorite_border</mat-icon>
                                        </button>
                                        <button mat-icon-button [disabled]="onCart()" (click)="addToCart()" matTooltip="Add To Cart">
                                            <mat-icon>shopping_cart</mat-icon>
                                        </button>  
                                    </div> 
                                </div> 
                            </div> 
                        </div>   
                    </div>  
                </mat-card>
                <mat-card fxFlex="33" fxFlex.xs="100" class="menu-item p-0" >   
                    <div fxFlex="100" [fxShrink]="(viewType == 'grid') ? '3' : '2'" class="thumbnail-section">
                        <mat-chip-listbox *ngIf="menuItem.discount">
                            <mat-chip-option color="warn" highlighted selectable="false">{{menuItem.discount}}% OFF</mat-chip-option>
                        </mat-chip-listbox> 
                
                    </div>  
                    <div fxFlex="100" class="p-3"> 
                        <div fxLayout="column" fxLayoutAlign="start stretch" class="h-100">
                            <mat-card-content class="mb-0 p-0">
                                <h1 class="title"><a [routerLink]="['/menu', menuItem.id]">{{menuItem.name}}</a></h1>   
                                <div class="mt-2"> 
                                    <p>{{menuItem.description}}</p> 
                                    <div *ngIf="viewType == 'list'" fxShow="false" fxShow.gt-sm="true">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis magnam illum, suscipit nostrum ea quidem. Architecto quisquam eligendi tempora, obcaecati delectus, odio natus suscipit vel est temporibus error officia esse!</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis magnam illum, suscipit nostrum ea quidem. Architecto quisquam eligendi tempora, obcaecati delectus, odio natus suscipit vel est temporibus error officia esse!</p>
                                    </div>  
                                </div>  
                            </mat-card-content>
                            <div class="sticky-footer-helper" fxFlex="grow"></div>
                            <div class="m-0 p-0">
                                <div class="features">     
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <mat-icon color="primary" class="mat-icon-sm">local_offer</mat-icon>
                                        <span class="px-1">Category:</span>
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ ( appService.Data.categories | filterById : menuItem.categoryId )?.name }}</span>
                                    </p>
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <mat-icon color="primary" class="mat-icon-sm">{{ (menuItem.availibilityCount > 0) ? 'check_circle' : 'unpublished'}}</mat-icon>
                                        <span class="px-1">Availibility:</span>
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ (menuItem.availibilityCount > 0) ? 'Available':'Unavailable'}}</span>
                                    </p>
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <span fxLayout="row" fxLayoutAlign="start center">
                                            <span class="mat-icon mat-icon-sm primary-color">
                                                <svg viewBox="0 0 24 24">
                                                    <path d="M12,3C10.73,3 9.6,3.8 9.18,5H3V7H4.95L2,14C1.53,16 3,17 5.5,17C8,17 9.56,16 9,14L6.05,7H9.17C9.5,7.85 10.15,8.5 11,8.83V20H2V22H22V20H13V8.82C13.85,8.5 14.5,7.85 14.82,7H17.95L15,14C14.53,16 16,17 18.5,17C21,17 22.56,16 22,14L19.05,7H21V5H14.83C14.4,3.8 13.27,3 12,3M12,5A1,1 0 0,1 13,6A1,1 0 0,1 12,7A1,1 0 0,1 11,6A1,1 0 0,1 12,5M5.5,10.25L7,14H4L5.5,10.25M18.5,10.25L20,14H17L18.5,10.25Z" />
                                                </svg>
                                            </span> 
                                            <span class="px-1">Weight:</span>
                                        </span>   
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ menuItem.weight }} g</span>
                                    </p>
                                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                                        <span fxLayout="row" fxLayoutAlign="start center"> 
                                            <span class="mat-icon mat-icon-sm primary-color">
                                                <svg viewBox="0 0 24 24">
                                                    <path *ngIf="!menuItem.isVegetarian" d="M20.84 22.73L15.14 17.03C13.26 18.79 10.92 20 8 20C7.64 20 7.14 19.87 6.66 19.7L5.71 22L3.82 21.34C5.15 18.03 6.5 14.32 9.66 11.55L8.77 10.66C6.76 12.03 4.86 14.1 3.75 17.25C3.75 17.25 2 15.5 2 13.5C2 12 3.12 9.32 5.72 7.61L1.11 3L2.39 1.73C2.39 1.73 16.39 15.74 16.39 15.74L22.11 21.46L20.84 22.73M17 8C15.35 8.37 13.93 8.88 12.7 9.5L17.5 14.29C20.87 9.35 22 3 22 3C21.03 4.95 14.35 5.24 9.38 6.18L12.15 8.95C14.81 8 17 8 17 8Z" />
                                                    <path *ngIf="menuItem.isVegetarian"  d="M17,8C8,10 5.9,16.17 3.82,21.34L5.71,22L6.66,19.7C7.14,19.87 7.64,20 8,20C19,20 22,3 22,3C21,5 14,5.25 9,6.25C4,7.25 2,11.5 2,13.5C2,15.5 3.75,17.25 3.75,17.25C7,8 17,8 17,8Z" />
                                                </svg>
                                            </span> 
                                        </span>  
                                        <span class="px-1">Vegetarian:</span>
                                        <span class="dots"></span>
                                        <span class="fw-500">{{ (menuItem.isVegetarian) ? 'Yes':'No'}}</span>
                                    </p>  
                                </div> 
                
                                <div fxLayout="row" [fxLayoutAlign]="(viewType == 'grid') ? 'space-between center' : 'end center'" class="mt-3">
                                    <span class="text-muted">Customer Review<span *ngIf="viewType == 'list'" class="px-2">:</span></span>
                                    <app-rating [ratingsCount]="menuItem?.ratingsCount" [ratingsValue]="menuItem?.ratingsValue"></app-rating>
                                </div>  
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1"> 
                
                                    <h2 *ngIf="!menuItem.discount" class="primary-color"> 
                                        {{menuItem.price | currency : 'USD' : 'symbol' : '1.2-2'}}  
                                    </h2> 
                                    <h2 *ngIf="menuItem.discount">  
                                        <span class="text-muted old-price">{{menuItem.price | currency : 'USD' : 'symbol' : '1.2-2'}}</span> 
                                        <span class="primary-color mx-2">{{ (menuItem.price - (menuItem.price * (menuItem.discount / 100))) | currency : 'USD' : 'symbol' : '1.2-2'}}</span>
                                    </h2> 
                
                
                                    <div class="text-muted">  
                                        <button mat-icon-button [disabled]="onFavorites()" (click)="addToFavorites()" matTooltip="Add To Favorite">
                                            <mat-icon>favorite_border</mat-icon>
                                        </button>
                                        <button mat-icon-button [disabled]="onCart()" (click)="addToCart()" matTooltip="Add To Cart">
                                            <mat-icon>shopping_cart</mat-icon>
                                        </button>  
                                    </div> 
                                </div> 
                            </div> 
                        </div>   
                    </div>  
                </mat-card>               
            </div>
        </div>                      
    </div>
</div>
<div class="logo"> 
  <svg
    version="1.0"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1000 557"
    style="enable-background: new 0 0 1000 557"
    xml:space="preserve"
  >

    <g
 
      stroke="none"
    >
      <path
        class="st0"
        d="M23.94,534.45L23.94,534.45c5.82,0.05,11.59,0.05,17.38,0.07C35.53,534.52,29.74,534.5,23.94,534.45z
       M109.43,490.95L109.43,490.95c0,2,0.05,4.06,0.05,6.06C109.48,494.98,109.45,492.95,109.43,490.95z M932.63,490.49
      C932.63,490.49,932.63,490.51,932.63,490.49c0.05,0.02,0.07,0.02,0.1,0c0.02,0,0.02,0,0.02,0v-0.02
      C932.7,490.46,932.68,490.49,932.63,490.49z"
      />
      <path
        class="st1"
        d="M353.64,360c0.02,11.76-4.15,21.58-12.7,29.57c-5.34,4.97-11.66,8.23-18.93,8.96
      c-7.05,0.72-14.22,0.92-20.95-1.93c-7.87-3.31-13.88-8.76-18.42-16.03c-4.06-6.47-4.66-13.54-4.66-20.83
      c-0.02-22.14,0.02-44.25-0.05-66.39c-0.02-5.6-1.38-10.94-6.06-14.51c-3.38-2.61-7.1-5.5-11.68-4.56
      c-8.09,1.64-15.45,5.24-15.47,16.39c-0.02,22.74,0,45.46-0.02,68.18c0,4.37-0.39,8.64-1.5,12.92
      c-4.18,16.39-19.36,29.24-38.99,27.5c-6.76-0.6-13.21-1.67-18.76-6.04c-4.49-3.55-7.99-7.77-10.82-12.84
      c-3.72-6.61-4.56-13.66-4.59-20.95c-0.05-22.57-0.17-45.17,0.05-67.72c0.07-8.84-6.11-15.26-13.35-18.11
      c-3.38-1.33-6.88-0.24-10.07,1.18c-5.87,2.63-9.68,6.95-10.14,13.64c-0.12,1.67-0.07,3.31-0.07,4.97
      c-0.02,22.43-0.05,44.85,0,67.28c0,10.19-3.23,19.17-10.16,26.63c-5.26,5.67-11.52,9.7-19.34,10.98
      c-18.49,3.02-32.98-3.4-42.08-20.38c-2.82-5.26-3.26-11.18-3.23-17.09v-55.12c0-18.66,0.07-37.32-0.07-56.01
      c-0.05-4.68,0.68-9.27,1.16-13.91c0.22-2.22,1.47-4.39,2.56-6.45c6.69-12.65,17.31-19.36,31.5-20.52c2-0.17,3.79,0.17,5.6,1.16
      c6.42,3.45,13.01,6.61,19.39,10.14c3.02,1.67,5.34,1.74,8.5-0.07c9.1-5.21,19.19-7.99,29.4-10.07c8.74-1.79,17.53-2.46,26.43-1.16
      c13.21,1.93,25.3,6.64,36.19,14.32c3.04,2.15,5.34,2.34,8.76,0.56c10.48-5.53,21.32-10.31,32.88-13.18
      c18.98-4.71,37.44-3.55,54.9,5.77c18.47,9.87,30.15,24.99,34.04,45.77c0.65,3.4,0.7,6.88,0.7,10.33
      C353.62,301.6,353.55,330.81,353.64,360z"
      />
      <path
        class="st1"
        d="M454.94,321.67c26.51-0.02,53.01-0.02,79.5,0c4.22,0.02,8.43-0.17,12.6-0.72c5.7-0.8,11.11-2.37,15.06-6.95
      c2.99-3.5,4.56-7.65,5.48-12.09c2.51-12.12-0.22-23.59-5.07-34.55c-6.33-14.32-15.6-26.44-27.76-36.45
      c-14.48-11.93-31.07-18.59-49.47-21.1c-13.54-1.86-26.94-1.13-40.29,1.91c-17.48,3.98-32.98,11.76-46.18,23.88
      c-7.48,6.88-13.52,15.09-18.18,24.09c-4.9,9.44-8.16,19.41-9.51,30.15c-1.57,12.14-0.8,24.17,1.64,35.85
      c2.41,11.54,7.94,22.04,14.87,31.87c8.62,12.24,19.94,21.05,32.78,27.98c10.04,5.41,20.74,8.91,32.18,10.67
      c15.6,2.39,31.12,3.26,46.79,1.57c14.34-1.5,28.46-4.1,41.86-9.7c7.56-3.19,14.48-7.22,19.53-13.93c4.66-6.2,7.31-13.01,6.23-20.88
      c-1.62-12.14-10.91-21.53-23.78-23.3c-7.97-1.09-15.57,0.65-23.08,2.78c-15.96,4.56-32.06,6.06-48.48,2.8
      c-8.84-1.76-16.1-6.01-21.73-13.45C451.73,321.38,453.35,321.67,454.94,321.67z M457.91,269.66c6.37-5.91,13.62-5.58,21.05-2.82
      c7.17,2.63,10.28,8.33,11.37,15.5c0.29,1.96-0.65,2.32-2.27,2.32c-6.93-0.05-13.86-0.02-20.76-0.02h-17.36
      C451.39,278.48,453.69,273.58,457.91,269.66z"
      />
      <path
        class="st1"
        d="M773.25,274.49c-0.02,28.29,0,56.59,0,84.88c-0.02,9.44-2.68,17.91-8.79,25.25
      c-8.93,10.74-20.35,15.11-34.23,13.91c-12.53-1.09-21.41-7.73-28.17-17.74c-2.63-3.89-4.15-8.26-4.49-13.06
      c-0.31-4.66-0.39-9.32-0.39-13.98c0-20.79,0.07-41.55-0.05-62.31c-0.05-8.23-4.22-14-10.94-18.69
      c-10.48-7.24-26.19,2.17-26.77,14.2c-0.12,2.1-0.22,4.2-0.22,6.3c-0.02,21.97-0.02,43.96,0,65.93c0,8.43-1.93,16.3-6.95,23.18
      c-8.76,12-20.4,17.41-35.37,15.86c-9.7-1.01-17.89-4.64-24.24-12.07c-3.38-3.96-6.35-8.43-7.56-13.35
      c-1.04-4.25-1.4-8.84-1.4-13.37c0.12-18.66,0.05-37.32,0.05-55.98c-0.02-17.91,0.07-35.83-0.07-53.71c-0.05-5,0.36-9.97,1.16-14.82
      c0.82-4.92,3.48-9.27,6.74-13.21c6.93-8.35,15.93-12.31,26.6-13.08c2.44-0.17,4.35,0.8,6.28,1.98c7.99,4.95,15.98,9.87,23.92,14.87
      c1.33,0.82,1.83,0.89,3.21-0.39c4.32-4.06,9.7-6.66,15.11-9.05c8.23-3.65,16.78-6.3,25.78-7.39c7.82-0.94,15.5-0.6,23.27,0.92
      c6.61,1.3,12.99,3.02,19.05,5.82c18.98,8.79,31.55,23.06,36.74,43.53C772.84,264.02,773.27,269.23,773.25,274.49z"
      />
      <path
        class="st1"
        d="M978.43,319.4c-0.51,15.09-3.45,29.65-10.89,43c-5.46,9.8-13.13,17.43-22.79,23.27
      c-13.52,8.16-28.44,11.2-43.77,13.08c-15.23,1.86-30.27,0.56-45.39-1.67c-9.61-1.42-18.64-4.32-27.18-8.52
      c-10.12-5-18.49-12.19-24.91-21.7c-5.21-7.75-9.17-16.15-10.79-25.16c-1.76-9.99-2.78-20.21-2.56-30.47
      c0.22-9.78,0.05-19.55,0.05-31.87c0.24-11.01-0.31-24.58,0.29-38.1c0.29-6.9,3.09-12.7,7.17-18.08
      c12.05-15.89,35.27-18.56,50.41-8.67c10.16,6.64,16.17,16.1,17.53,28.29c0.29,2.53,0.24,5.12,0.24,7.65
      c0.02,19.58,0,39.13,0.05,58.69c0,2.7,0.12,5.43,0.43,8.11c0.8,7.39,4.88,12.31,11.81,14.75c8.96,3.16,19.53-2.29,22.79-11.15
      c1.33-3.62,1.64-7.17,1.64-10.91c-0.02-20.76,0.02-41.52-0.02-62.29c-0.02-7.73,1.55-15.09,5.67-21.58
      c5.17-8.09,12.46-13.69,21.92-16.01c13.74-3.38,26.27-0.72,36.67,8.98c7.65,7.12,11.66,16.2,11.73,26.8
      c0.07,16.25,0.05,32.52,0.02,48.74C978.52,302.86,978.67,311.14,978.43,319.4z"
      />
      <path
        class="st2"
        d="M741.67,142.66c0-1.11-0.56-1.67-1.62-1.67c-1.21-0.05-2.41-0.02-3.62-0.02h-67.28
      c-7.36,0-14.75,0.05-22.11-0.05c-2.17-0.05-2.85,0.72-2.8,2.87c0.12,5.87,0.02,11.76,0.05,17.62c0.02,3.69,0,3.38-3.48,3.38H584.8
      c-3.77,0-7.53-0.05-11.3,0.02c-1.71,0.05-2.37-0.56-2.34-2.32c0.07-6.47,0.12-12.94-0.02-19.43c-0.05-2.22,0.72-2.85,2.87-2.8
      c6.16,0.12,12.34-0.05,18.49,0.1c2.34,0.05,3.11-0.65,3.09-3.04c-0.12-7.15,0.02-14.29,0.07-21.44c0.6,0.43,1.33,0.41,2,0.41
      c6.18,0,12.34,0.02,18.49-0.02c3.38-0.02,3.43-0.07,3.45-3.55c0.02-5.87-0.05-11.73,0.05-17.58c0.02-1.38-0.72-3.11,1.23-3.91
      c7.56-0.46,15.14-0.6,22.69,0.07h0.02c0.27-0.1,0.8-0.17,0.82-0.29c0.92-7.31,0.29-14.68,0.34-22.04c0-0.89-0.65-1.67-1.76-1.67
      c-1.06-0.05-2.1-0.05-3.14-0.05h-87.61c-0.89,0-1.83,0.05-2.7,0c-1.59-0.12-2.15,0.65-2.1,2.12c0.05,1.06,0.02,2.1,0,3.16
      c-0.02,13.4-0.02,26.8-0.07,40.2c0,3.4-0.05,3.45-3.48,3.48c-5.43,0.05-10.84,0.12-16.27-0.05c-2.27-0.05-2.85,0.8-2.8,2.87
      c0.12,5.26,0.07,10.55,0.05,15.81c-0.05,6.11,0.75,5.34-5.17,5.38c-5.58,0.05-11.15,0.05-16.71-0.02c-2.1-0.05-3.33,0.22-3.23,2.87
      c0.22,6.18,0.1,12.34,0.05,18.52c0,1.57,0.29,2.66,2.17,2.56c1.04-0.05,2.1,0,3.16,0c12.94,0,25.88-0.02,38.84,0
      c3.4,0.02,3.45,0.07,3.48,3.52c0.05,5.87-0.02,11.76,0.02,17.62c0.02,3.43,0.05,3.5,3.43,3.5c30.13,0.02,60.21,0.02,90.31,0
      c3.52-0.02,3.62-0.12,3.62-3.5c0.05-5.72,0-11.44,0.05-17.16c0.02-3.23,0.05-3.28,3.11-3.31c5.87-0.05,11.76,0.07,17.62-0.05
      c2.27-0.05,3.19,0.56,3.11,2.99c-0.14,6.04,0,12.05-0.07,18.08c-0.05,1.96,0.29,3.04,2.68,3.04c14.44-0.1,28.9-0.1,43.36,0
      c2.27,0,2.8-0.84,2.78-2.95c-0.1-5.7-0.02-11.42-0.05-17.14c0-4.15-0.22-3.98,3.89-3.98c6.04,0.02,12.05,0,18.08,0
      c1.28,0.02,2.37-0.05,2.37-1.83C741.65,156.2,741.65,149.42,741.67,142.66z M571.16,93.79c0-1.64,0.46-2.46,2.27-2.44
      c6.61,0.05,13.21,0.1,19.8-0.02c2.27-0.05,2.29,1.18,2.29,2.82c-0.05,7.19,0,14.36,0.02,21.53c-0.53,0.19-1.01,0.46-1.55,0.46
      c-6.76,0.05-13.5,0-20.25,0.07c-1.93,0.02-2.61-0.68-2.58-2.61C571.25,107.02,571.21,100.41,571.16,93.79z"
      />
      <path
        class="st2"
        d="M913.15,183.99V62.94c0-1.06,0-2.1-0.02-3.16c-0.02-1.59-0.7-2.56-2.44-2.49c-0.89,0.05-1.81,0-2.7,0H786.04
      c-0.89,0-1.81,0.05-2.7,0c-1.3-0.05-1.93,0.53-1.91,1.86c0.05,1.06,0,2.1,0,3.16v60.96c0,20.79,0,41.55,0.02,62.33
      c0,3.4,0.05,3.4,3.4,3.45h123.29C913.73,189.08,913.15,189.73,913.15,183.99z M894.7,167.62c-0.02,3.16-0.1,3.21-3.28,3.21
      c-14.75,0.05-29.5,0.02-44.25,0.02c-14.58,0-29.19,0.02-43.79,0c-3.67-0.02-3.72-0.05-3.72-3.69c-0.02-29.36-0.02-58.71,0-88.04
      c0-3.4,0.05-3.45,3.48-3.45c29.36-0.02,58.71-0.02,88.04,0c3.45,0,3.52,0.05,3.52,3.45C894.73,108.59,894.73,138.12,894.7,167.62z"
      />
      <path
        class="st2"
        d="M996.31,119.43c-0.58,7.22-5.29,11.61-13.33,10.77c-3.11-0.31-7.22-5.12-6.98-7.77
      c0.17-1.93-0.12-3.89-0.12-5.84c-0.02-29.65-0.02-59.32-0.02-88.96c0-0.89-0.1-1.83,0.02-2.7c0.36-2.51-0.89-2.95-3.11-2.95
      c-12.77,0.1-25.59,0.05-38.39,0.05c-17.91,0-35.83,0-53.74-0.02c-1.79,0-3.6-0.12-5.38-0.31c-3.65-0.43-6.45-3.23-6.9-6.81
      c-0.14-1.18-0.24-2.39-0.29-3.6c-0.12-4.51,0.99-7.82,8.43-9.27c1.67,0.05,3.28,0.14,4.92,0.14c24,0.02,47.99,0.02,71.99,0.02
      c11.56-0.02,23.1-0.07,34.64-0.12c5.99,2.03,8.23,5.14,8.23,11.68c0.02,33.12,0,66.24,0,99.37
      C996.34,115.23,996.48,117.33,996.31,119.43z"
      />
      <path
        class="st2"
        d="M996.34,438.65c0,34.74,0,69.5,0.02,104.27c0,4.59-1.3,8.45-5.5,10.98c-0.94,0.56-1.71,0.99-2.78,0.99
      c-1.67,0.02-3.31,0.12-4.97,0.12H881.14c-1.35,0-2.7-0.05-4.06-0.05c-5.53,0.14-8.98-4.1-9-9.37c-0.05-6.28,2.51-9.7,8.52-10.6
      c0.58,0.05,1.18,0.14,1.79,0.14c31.29,0,62.6,0,93.86-0.02c1.16,0,2.44,0.39,3.45-0.56c0.07-1.64,0.22-3.28,0.22-4.95v-91.13
      c0-3.11-0.05-6.28,2.44-8.62c2.99-2.87,6.59-3.91,10.57-2.61c4.37,1.4,6.69,4.71,7.41,9.17
      C996.41,437.13,996.34,437.91,996.34,438.65z"
      />
      <path
        class="st2"
        d="M125.89,554.34c-0.72,0.43-1.33,0.48-2.03,0.53c-1.67,0.07-3.31,0.14-4.97,0.14H16.39
      c-1.64,0-3.31-0.05-4.95-0.22c-4.37-0.43-7.19-3.28-7.6-7.65c-0.17-1.64-0.22-3.28-0.22-4.95V439.67c0-1.35-0.05-2.7,0.07-4.06
      c0.41-5.17,3.04-8.5,9.7-8.84c5.09-0.24,9.73,3.86,10.28,8.81c0.17,1.5,0.1,2.99,0.1,4.49v89.42c0,1.67,0.12,3.31,0.14,4.95
      l0.02,0.02l99.68,0.6c5.26,0.92,7.63,4.76,8.09,9.39C132.14,548.74,129.61,552.05,125.89,554.34z"
      />
      <path
        class="st2"
        d="M125.87,21.34c-0.72,0.46-1.35,0.46-2.05,0.48c-1.81,0.1-3.62,0.14-5.41,0.14C88.88,22,59.41,22,29.91,22
      h-2.27c-4.18-0.02-3.86-0.22-3.86,3.94c0,30.71-0.05,61.42,0.05,92.12c0.02,4.73-1.57,8.4-5.67,10.79
      c-3.74,2.2-9.97,1.13-12.65-2.1c-0.94-1.16-1.69-2.34-1.67-3.96c0.05-1.81-0.19-3.62-0.19-5.43C3.62,83.05,3.62,48.72,3.62,14.42
      c0-1.21-0.02-2.41,0.05-3.62C4,5.19,6.2,2.9,12.09,1.98c1.64,0.05,3.28,0.14,4.92,0.14c24,0.02,47.99,0.02,71.99,0.02
      c11.56-0.02,23.1-0.07,34.64-0.12c4.92,0.99,7.56,4.42,8.06,9C132.24,15.55,129.68,18.93,125.87,21.34z"
      />
      <path
        class="st2"
        d="M109.33,490.95c0-0.05,0-0.05,0.02-0.1h0.07L109.33,490.95z"
      />
      <path
        class="st2"
        d="M129.49,468.9c0.05,2.12-0.58,2.92-2.8,2.87c-4.66-0.14-9.32,0.05-13.98-0.1c-2.34-0.07-3.09,0.68-3.09,3.07
      c0,5.36,0.46,10.74-0.27,16.1c-5.38,0.05-10.77,0.14-16.15,0.05c-2.22-0.05-2.87,0.72-2.8,2.85c0.12,4.66-0.05,9.34,0.07,14
      c0.05,2.15-0.65,2.92-2.85,2.87c-4.66-0.12-9.32-0.12-13.98-0.02c-1.93,0.05-2.68-0.68-2.66-2.61c0.07-4.66-0.05-9.32,0.05-13.98
      c0.07-2.27-0.56-3.21-3.04-3.09c-4.49,0.19-9,0-13.54,0.07c-1.83,0.05-2.68-0.43-2.68-2.51c0.07-11.27,0.12-22.57-0.02-33.85
      c-0.02-2.44,1.13-2.63,3.04-2.61c9.92,0.05,19.87,0.02,29.77,0c1.21,0,2.41-0.05,3.62,0c1.26,0.02,1.98-0.43,1.96-1.81
      c-0.02-5.12-0.02-10.24,0-15.35c0.02-1.76-1.11-1.83-2.41-1.81c-4.66,0-9.32,0.02-13.98-0.02c-3.26-0.02-3.28-0.05-3.33-3.04
      c-0.05-4.51,0.12-9.03-0.05-13.54c-0.12-2.51,0.92-2.99,3.14-2.97c10.98,0.07,21.94,0.07,32.93-0.02c2.37-0.02,3.04,0.75,3.04,3.11
      c-0.12,10.53-0.05,21.05-0.05,31.58c0.02,4.2-0.39,3.89,3.89,3.89c4.66,0.02,9.34,0.05,13.98-0.02c1.5-0.02,2.17,0.41,2.17,2.03
      C129.42,459.01,129.37,463.96,129.49,468.9z"
      />
      <path
        class="st2"
        d="M932.65,490.51C932.63,490.51,932.63,490.49,932.65,490.51c0.02-0.02,0.05-0.05,0.1-0.05v0.02c0,0,0,0-0.02,0
      c-0.02,0.02-0.07,0.07-0.07,0.07V490.51z"
      />
      <path
        class="st2"
        d="M952.06,469.12c0.05,1.93-0.6,2.68-2.58,2.63c-4.97-0.1-9.92-0.02-14.9-0.05c-1.28,0-2.44,0.02-2.34,1.81
      c0.27,5.67-0.51,11.32,0.39,16.97c-0.84,0.22-1.69,0.34-2.53,0.41c-3.57,0.34-7.17-0.19-10.74,0.02c-1.06,0.07-2.1,0-3.16,0.02
      c-3.36,0.05-3.38,0.07-3.4,3.52c-0.05,4.51-0.07,9.03,0,13.54c0.05,1.96-0.7,2.63-2.63,2.58c-4.66-0.1-9.32-0.12-13.98,0.02
      c-2.34,0.07-2.78-0.97-2.7-2.95c0.1-4.51,0.05-9.03,0-13.54c-0.02-3.09-0.07-3.11-3.28-3.16c-4.37-0.05-8.72-0.12-13.08,0.05
      c-2.27,0.1-2.73-0.77-2.7-2.87c0.1-11.13,0.1-22.26,0-33.36c-0.02-2.2,0.7-2.78,2.82-2.78c9.61,0.1,19.24,0.05,28.85,0.02
      c1.21,0,2.44-0.1,3.62,0.02c2.03,0.19,2.99-0.39,2.9-2.68c-0.17-4.66-0.1-9.32-0.02-13.98c0.05-1.71-0.56-2.37-2.32-2.34
      c-4.97,0.1-9.92,0.02-14.9,0.05c-1.45,0.02-2.73,0-2.61-2.05c0.29-5.67-0.51-11.39,0.41-17.04l38.17-0.05
      c0.97,1.38,0.51,2.92,0.51,4.39c0.05,9.75,0.02,19.53,0.05,29.28c0,5.09-0.43,4.44,4.66,4.49c4.2,0.02,8.43,0.07,12.6-0.02
      c2-0.05,3.02,0.36,2.95,2.68C951.92,459.49,951.97,464.29,952.06,469.12z"
      />
      <path
        class="st1"
        d="M254.11,154.07c0.02,1.83-0.92,2.58-2.29,3.4c-5.58,3.28-11.83,4.18-18.01,5.26
      c-6.71,1.16-13.64,1.11-20.21-0.12c-8.69-1.64-16.03-6.33-21.2-13.91c-6.66-9.75-7.87-20.47-6.54-31.96
      c0.99-8.69,4.66-15.74,10.86-21.68c4.44-4.25,9.73-6.86,15.67-8.26c12.87-3.07,25.25-1.5,37.2,3.96c2.9,1.33,2.85,1.47,1.64,4.27
      c-1.5,3.55-3.02,7.05-4.66,10.86c-8.33-4.8-16.73-6.54-25.69-5.48c-9.58,1.16-16.32,7.15-18.54,16.54c-1.52,6.45-1.33,12.87,1.4,19
      c3.11,7,8.64,11.13,16.1,12.34c5.41,0.87,10.79,0.39,16.08-1.18c2.1-0.63,2.95-1.67,2.78-3.84c-0.14-2.1-0.1-4.22-0.05-6.3
      c0.05-1.55-0.56-2.17-2.1-2.15c-3.62,0.07-7.22,0.05-10.84,0c-3.28-0.05-3.31-0.05-3.38-3.11c-0.05-3.02,0.05-6.04-0.02-9
      c-0.05-1.67,0.46-2.51,2.27-2.51c9.03,0.05,18.06,0.05,27.09,0c1.69,0,2.37,0.6,2.37,2.34
      C254.04,133.02,254.04,143.55,254.11,154.07z"
      />
      <path
        class="st1"
        d="M148.05,116.08c-1.67-9.29-6.74-16.54-14.56-21.8c-7.15-4.8-15.16-6.95-23.63-7.31
      c-7.36-0.34-14.75-0.22-22.11-0.22c-3.11,0-3.21,0.1-3.21,3.33c-0.02,11.44,0,22.89,0,34.33v21.24c0,4.51,0.1,9.03-0.05,13.54
      c-0.07,2.34,0.8,3.16,3.11,3.04c3.31-0.14,6.61,0,9.95-0.05c6.93-0.05,13.83,0.22,20.71-1.13c10.28-1.98,18.59-6.93,24.41-15.72
      C148.58,136.38,149.91,126.43,148.05,116.08z M131.78,132.08c-1.59,6.4-5.58,10.89-11.73,13.33c-5.84,2.32-12,2.37-18.18,2.29
      c-2.03-0.02-1.98-1.38-1.98-2.78c0.02-6.9,0-13.81,0-20.71c0-6.47-0.02-12.94,0.02-19.39c0.02-3.52,0.07-3.57,3.38-3.6
      c5.12-0.05,10.19,0.1,15.11,1.79c7.82,2.66,12.43,7.92,13.88,16.1C133.06,123.54,132.84,127.86,131.78,132.08z"
      />
      <path
        class="st1"
        d="M422.49,159.68c-5.77-15.45-11.56-30.9-17.29-46.35c-2.95-7.94-5.82-15.91-8.72-23.88
      c-0.65-1.83-1.64-2.85-3.89-2.75c-4.37,0.22-8.74,0.05-13.11,0.1c-1.35,0.02-2.1,0.36-2.7,2.1c-3.28,9.27-7,18.32-10.45,27.55
      c-4.68,12.55-9.25,25.16-13.86,37.73c-0.89,2.44-2.1,4.83-1.76,8.06c4.66,0,9-0.02,13.37,0.02c1.57,0.02,2.34-0.56,2.82-2.17
      c1.33-4.25,2.97-8.4,4.44-12.6c0.56-1.55,1.3-2.51,3.19-2.49c8.14,0.07,16.27,0.05,24.38,0.02c1.33,0,2.05,0.31,2.51,1.86
      c1.3,4.27,3.14,8.38,4.39,12.65c0.58,2.05,1.67,2.68,3.45,2.7c4.49,0.05,8.98,0.02,13.23,0.02
      C423.1,161.05,422.76,160.38,422.49,159.68z M377.69,130.13c2.87-8.06,5.7-16.01,8.55-23.95c0.31,0.02,0.65,0.02,0.99,0.05
      c2.8,7.85,5.62,15.69,8.57,23.9L377.69,130.13L377.69,130.13z"
      />
      <path
        class="st1"
        d="M357.89,89.91c0.05,2.68,0.05,5.41,0,8.11c-0.05,3.11-0.1,3.16-3.38,3.19c-5.58,0.05-11.15,0.02-16.71,0.02
      c-4.9,0-4.3-0.02-4.3,4.49v51.47c0,1.06-0.05,2.1,0,3.16c0.05,1.35-0.65,1.86-1.93,1.86c-3.77-0.02-7.51-0.05-11.27,0.02
      c-1.86,0.05-2.34-0.89-2.22-2.51c0.05-1.06,0-2.1,0-3.16V106.9c0-1.06-0.07-2.12,0.02-3.16c0.19-2.05-0.84-2.56-2.68-2.53
      c-4.95,0.07-9.92,0.02-14.9,0.02c-6.83,0-6.88,0-6.78-6.98c0.05-2.34-1.09-5.31,0.6-6.93c1.67-1.59,4.59-0.53,6.95-0.56
      c8.26-0.07,16.54-0.05,24.82-0.05c9.46,0,18.95-0.02,28.44,0.02C357.82,86.74,357.82,86.82,357.89,89.91z"
      />
      <path
        class="st2"
        d="M741.67,91.89c0,7.05,0.02,14.12-0.02,21.2c0,3.11-0.05,3.19-3.28,3.19c-14.12,0.05-28.25,0.05-42.37,0
      c-3.28,0-3.36-0.05-3.38-3.09c-0.05-6.3,0.1-12.6-0.07-18.93c-0.05-2.46,0.87-3.04,3.11-2.97c6.16,0.12,12.31,0.05,18.47,0.05
      c3.45-0.02,3.52-0.05,3.55-3.45c0.05-5.84,0.1-11.73-0.02-17.58c-0.05-2.03,0.34-2.95,2.68-2.87c6.01,0.17,12,0.02,18.03,0.07
      c3.21,0.02,3.26,0.07,3.28,3.21C741.69,77.74,741.67,84.84,741.67,91.89z"
      />
      <path
        class="st1"
        d="M479.73,151.15c0.05,2.56,0.05,5.12,0,7.68c-0.05,3.28-0.07,3.36-3.6,3.38c-13.98,0.02-27.98,0.02-41.93,0
      c-3.67,0-3.72-0.05-3.72-3.72c-0.05-11.42-0.02-22.86-0.02-34.28c0-10.69,0-21.37,0.02-32.04c0-6.08-0.6-5.38,5.65-5.43h4.95
      c5.31,0.02,5-0.43,5,4.76v17.6c0,11.73-0.02,23.47,0.02,35.17c0,3.38,0.05,3.43,3.48,3.45c8.88,0.05,17.74-0.02,26.63,0.02
      C479.66,147.75,479.66,147.8,479.73,151.15z"
      />
      <path
        class="st1"
        d="M285.59,91.74v32.93c0,11.27,0,22.55-0.02,33.82c0,3.62-0.05,3.65-3.72,3.69c-3.16,0.05-6.33-0.05-9.49,0
      c-1.47,0.02-2.27-0.48-2.17-2.08c0.05-0.89,0-1.81,0-2.7V91.57c0-1.06,0.02-2.1,0.02-3.16c0-1.09,0.6-1.64,1.67-1.64
      c4.06-0.02,8.14-0.02,12.17,0.02c1.16,0,1.5,0.77,1.5,1.81C285.56,89.64,285.59,90.7,285.59,91.74z"
      />
      <path
        class="st1"
        d="M174.88,91.77v65.35c0,0.89-0.05,1.81,0,2.7c0.12,1.71-0.58,2.44-2.34,2.39c-3.31-0.07-6.61-0.12-9.92,0.02
      c-2.27,0.12-3.21-0.65-3.11-3.04c0.12-4.2,0.05-8.43,0.05-12.6V124.5c0-11.42-0.02-22.84,0-34.26c0.02-3.43,0.07-3.45,3.48-3.5
      c3.16-0.05,6.33,0.05,9.46-0.02c1.69-0.05,2.51,0.6,2.39,2.34C174.8,89.95,174.88,90.87,174.88,91.77z"
      />
      <path
        class="st2"
        d="M692.69,89.21c0,1.47-0.56,2.17-2.12,2.17c-7.19-0.05-14.39-0.02-21.92-0.02c0-7.89,0-15.52-0.02-23.18
      c0-1.3,0.46-1.93,1.83-1.93c7.31,0.02,14.65,0,22.21,0C692.66,74.21,692.66,81.7,692.69,89.21z"
      />
      <path
        class="st2"
        d="M668.67,114.19c0.02,1.55-0.63,2.1-2.12,2.1c-6.59-0.05-13.21-0.05-19.8,0c-1.59,0.02-2-0.77-2-2.22
      c0.05-6.88,0.05-13.81,0-20.71c-0.02-1.59,0.65-2.05,2.15-2.05c6.61,0.05,13.21,0.05,19.8,0c1.67-0.02,2,0.75,2,2.17
      c-0.05,3.45-0.02,6.9-0.02,10.36S668.59,110.74,668.67,114.19z"
      />
      <polygon
        class="st2"
        points="109.43,490.85 109.43,490.95 109.33,490.95 	"
      />
      <path
        class="st2"
        d="M129.01,508.18c0.05,2.12-1.11,2.41-2.85,2.39c-4.51-0.07-9.03-0.12-13.54,0.02
      c-2.32,0.07-3.16-0.65-3.11-3.04c0.07-3.52,0.05-7.03-0.02-10.55c-0.02-2-0.05-4.06-0.07-6.06h0.02
      c5.53-0.02,11.06,0.05,16.59-0.07c2.17-0.05,2.99,0.8,2.97,2.87C128.96,498.55,128.93,503.35,129.01,508.18z"
      />
      <path
        class="st2"
        d="M932.75,490.49c0,0.02-0.02,0.05-0.02,0.07h-0.07C932.65,490.56,932.7,490.51,932.75,490.49L932.75,490.49z"
      />
      <path
        class="st2"
        d="M951.41,507.8c0.07,2.22-0.75,2.87-2.87,2.8c-4.66-0.12-9.29-0.05-13.95-0.05c-1.04,0-2.41,0.17-2.37-1.33
      c0.17-6.23-0.82-12.46,0.51-18.66c5.19,0.72,10.38,0.27,15.6,0.29c2.29,0,3.16,0.6,3.09,3.02
      C951.24,498.48,951.27,503.16,951.41,507.8z"
      />
      <path
        class="st1"
        d="M41.32,534.55c-5.79-0.02-11.59-0.05-17.38-0.05l-0.02-0.02C29.74,534.5,35.53,534.52,41.32,534.55z"
      />
      <path
        class="st0"
        d="M595.66,115.64l-0.12,0.12v-0.07C595.57,115.67,595.61,115.67,595.66,115.64z"
      />
      <path
        class="st2"
        d="M875.8,149.03c0.02,2.51-1.06,2.92-3.26,2.9c-16.97-0.05-33.97-0.07-50.94,0.02c-2.37,0-3.11-0.75-3.07-3.09
      c0.12-8.72,0.05-17.43,0.05-26.15c0-8.28-0.02-16.54,0.02-24.82c0-3.23,0.05-3.31,3.16-3.33c17-0.02,33.97,0.05,50.96-0.05
      c2.39,0,3.11,0.72,3.09,3.11C875.73,114.75,875.7,131.91,875.8,149.03z"
      />
    </g>
    <g
      
      stroke="none"
    >
      <path
        class="st3"
        d="M392.61,440.88c-2.46,9.37-4.95,18.76-7.56,28.66c-0.36-1.01-0.56-1.5-0.7-1.98
      c-2.12-8.23-4.22-16.44-6.42-24.65c-0.22-0.77-0.8-2.05-1.23-2.08c-3.72-0.17-7.46-0.1-11.15-0.1v37.64h7.53v-25.4
      c0.14-0.02,0.29-0.02,0.43-0.05c2.32,8.5,4.64,17,6.98,25.66c2.46,0,4.78-0.1,7.1,0.02c1.47,0.07,2.08-0.41,2.44-1.88
      c1.83-7.15,3.81-14.22,5.72-21.32c0.22-0.82,0.51-1.64,0.77-2.44c0.14,0.02,0.31,0.05,0.46,0.07v25.35h7.53V440.9h-11.9V440.88z
       M648.07,472.84c4.2-4.9,5.53-10.65,4.42-16.87c-2.03-11.37-12.55-17.96-24.21-15.38c-10.48,2.34-16.87,13.21-14.15,24.07
      c2.87,11.32,14.75,17.41,26.17,13.28c1.57-0.58,2.44-0.29,3.4,0.89c1.5,1.76,3.16,3.38,4.61,4.95c1.47-1.3,2.78-2.49,4.22-3.77
      c-1.93-2.03-3.72-3.94-5.6-5.89C647.4,473.64,647.74,473.23,648.07,472.84z M641.48,468.35c-1.38-1.57-2.73-3.14-4.2-4.85
      c-1.57,1.47-2.82,2.66-4.22,3.98c1.16,1.3,2.2,2.51,3.31,3.77c-5.12,1.83-11.01-0.7-13.42-5.6c-2.82-5.75-1.09-13.18,3.81-16.37
      c4.8-3.11,11.66-2.17,15.21,2.05C645.85,455.92,645.73,463.76,641.48,468.35z M466.94,440.83v27.14c-0.72-0.56-1.06-1.21-1.33-1.83
      c-3.45-7.85-6.88-15.69-10.36-23.51c-0.34-0.72-1.06-1.76-1.67-1.76c-3.28-0.19-6.59-0.1-9.92-0.1v37.66h7.56v-26.7
      c0.77,0.72,1.13,1.45,1.45,2.2c3.36,7.65,6.69,15.28,10.07,22.91c0.29,0.68,0.97,1.67,1.47,1.67c3.36,0.14,6.71,0.07,10.04,0.07
      v-37.73L466.94,440.83L466.94,440.83z M558.97,458.26c2.58-2.56,4.06-5.29,3.65-8.69c-0.56-4.73-4.42-8.11-10.21-8.5
      c-5.24-0.34-10.53-0.22-15.76-0.29c-0.29,0-0.56,0.17-0.77,0.24v37.47c5.89,0,11.61,0.24,17.31-0.05
      c6.18-0.34,10.43-4.06,11.25-9.27C565.22,464.37,563.19,460.84,558.97,458.26z M543.47,447.88c2.82,0.12,5.5,0.12,8.18,0.46
      c1.76,0.22,3.04,1.33,3.09,3.31c0.05,2.1-1.16,3.31-3.07,3.55c-2.66,0.31-5.36,0.29-8.21,0.43L543.47,447.88L543.47,447.88z
       M553.63,470.88c-3.31,0.41-6.66,0.46-10.14,0.68v-8.91c3.4,0.17,6.61,0.19,9.8,0.56c1.96,0.22,3.26,1.4,3.33,3.6
      C556.72,469,555.8,470.62,553.63,470.88z M758.11,440.08c-11.39-0.05-19.87,8.26-19.87,19.51c-0.02,11.18,8.33,19.53,19.55,19.55
      c11.32,0.05,19.65-8.16,19.7-19.36C777.54,448.5,769.31,440.15,758.11,440.08z M757.89,471.75c-6.86,0-11.61-5.05-11.59-12.22
      c0.02-7.05,4.83-12.09,11.49-12.14c6.81-0.05,11.59,4.95,11.61,12.17C769.46,466.83,764.77,471.78,757.89,471.75z M234.58,457.65
      h-15.6v7.22H227c0,1.69,0.05,3.19-0.05,4.66c-0.02,0.41-0.39,0.99-0.75,1.21c-4.51,2.68-12.84,0.92-15.72-3.52
      c-3.02-4.68-3.11-9.73-0.41-14.56c2.61-4.66,7.15-5.77,12.07-5.12c3.02,0.39,5.91,1.47,9.05,2.27c0.82-1.83,1.71-3.98,2.61-6.04
      c-2.41-1.86-5.17-2.8-9.97-3.52c-12.6-1.83-22.02,5-23.54,17.14c-1.28,10.31,5.12,19.46,15.14,21.32
      c5.02,0.92,10.04,0.27,14.92-1.23c4.39-1.33,4.39-1.38,4.39-5.89v-11.73C234.72,459.18,234.63,458.45,234.58,457.65z
       M170.29,442.61c-6.71-2.61-13.71-1.76-20.76-1.86v38.07c5.67-0.29,11.23-0.1,16.59-0.89c8.88-1.35,14.65-7.89,15.4-16.44
      C182.26,452.66,178.16,445.7,170.29,442.61z M172.1,465.69c-3.09,6.01-9.03,5.6-14.92,5.67v-23.3c5.12-0.17,10.31-0.36,13.88,4.03
      C174.46,456.21,174.42,461.18,172.1,465.69z M804.49,442.95c-6.93-3.04-14.24-2.1-21.58-2.2v38.07c5.7-0.29,11.23-0.1,16.61-0.89
      c8.69-1.33,14.32-7.53,15.33-16.01C815.86,453.48,811.92,446.21,804.49,442.95z M805.09,466.37c-3.31,5.34-8.96,4.92-14.48,5
      v-23.39c5.6,0.07,11.2-0.22,14.53,5.09C807.84,457.34,807.75,462.14,805.09,466.37z M679.82,463.13c0.36-0.27,0.53-0.39,0.72-0.48
      c5-2.87,7.05-7.15,6.06-12.63c-0.82-4.59-4.66-8.47-9.87-8.86c-5.89-0.43-11.83-0.1-17.99-0.1v37.37h7.56V464.1
      c0.89,0,1.5,0.1,2.1-0.02c2.49-0.41,3.96,0.58,5.17,2.8c1.93,3.57,4.2,6.95,6.37,10.38c0.36,0.56,1.06,1.21,1.64,1.23
      c2.44,0.12,4.9,0.05,7.8,0.05C686.02,473.18,682.91,468.18,679.82,463.13z M675.47,456.86c-2.95,0.39-5.94,0.41-9.13,0.63v-9.68
      c3.14,0.22,6.2,0.22,9.2,0.7c2.29,0.34,3.33,1.96,3.26,4.18C678.73,454.88,677.69,456.54,675.47,456.86z M514.52,445.61v-4.76
      h-7.65v2.75c0,6.54,0.05,13.11-0.05,19.63c-0.05,4.73-1.83,7.53-5.5,8.23c-2,0.39-4.22,0.39-6.25,0c-3.52-0.65-5.31-3.23-5.38-7.56
      c-0.12-6.86-0.05-13.69-0.07-20.54c-0.02-0.82-0.07-1.59-0.12-2.49h-7.31c-0.12,0.34-0.22,0.56-0.22,0.75
      c0,8.14-0.19,16.27,0.12,24.38c0.27,7.07,5.19,12.02,12.24,12.89c1.86,0.22,3.77,0.27,5.62,0.17c9.49-0.56,14.51-5.84,14.58-15.38
      C514.57,457.65,514.52,451.62,514.52,445.61z M318.69,476.12c-3.6-9.73-7.19-19.46-10.82-29.19c-0.77-2.03-1.11-4.78-2.61-5.82
      c-1.5-1.04-4.22-0.22-6.37-0.43c-1.74-0.17-2.34,0.65-2.87,2.1c-3.21,8.84-6.47,17.65-9.73,26.43c-1.11,3.02-2.25,6.04-3.45,9.34
      c2.56,0,4.83,0.1,7.05-0.05c0.53-0.05,1.23-0.75,1.45-1.33c0.77-1.79,1.47-3.62,2-5.5c0.39-1.47,1.21-1.81,2.61-1.76
      c3.55,0.07,7.07,0.07,10.62,0c1.33-0.05,2.05,0.34,2.44,1.67c0.58,1.93,1.3,3.84,2.08,5.72c0.22,0.51,0.82,1.16,1.3,1.21
      c2.27,0.12,4.56,0.05,7.1,0.05C319.14,477.5,318.93,476.8,318.69,476.12z M296.62,462.56c1.5-4.2,2.92-8.14,4.56-12.75
      c1.64,4.56,3.09,8.55,4.59,12.75H296.62z M420.66,471.39v-8.64h14.97v-7.34h-15.04v-7.48h16.56v-7.03h-24.14v37.52h24.31v-7.03
      H420.66z M828.6,471.39v-8.64h14.97v-7.34h-15.04v-7.48h16.56v-7.03h-24.14v37.52h24.31v-7.03H828.6z M715.67,468.04
      c-3.43-4.06-4.01-11.52-0.65-15.84c5.58-7.15,12.43-5.21,19.39-2c0.97-2.2,1.88-4.32,2.87-6.54c-9.34-5.94-22.09-4.39-28.25,3.21
      c-6.11,7.6-5.53,19.92,1.21,26.8c6.35,6.47,19.87,7.39,26.99,1.79c-0.97-2.15-1.91-4.32-2.87-6.47
      C725.66,472.98,719.56,472.62,715.67,468.04z M592.52,440.68c-1.81-0.12-2.82,0.46-3.65,2.15c-2.25,4.54-4.73,8.98-7.24,13.71
      c-2.61-4.97-5.05-9.61-7.56-14.22c-0.34-0.65-1.06-1.5-1.67-1.52c-2.51-0.17-5.05-0.07-7.94-0.07c0.56,1.06,0.89,1.79,1.3,2.49
      c3.62,6.4,7.31,12.77,10.86,19.24c0.68,1.23,1.06,2.78,1.13,4.2c0.14,3.89,0.05,7.8,0.05,11.81h7.68c0-4.1-0.07-7.99,0.05-11.9
      c0.05-1.28,0.36-2.68,0.99-3.81c3.62-6.59,7.36-13.11,11.06-19.63c0.39-0.68,0.68-1.38,1.18-2.41
      C596.41,440.71,594.45,440.83,592.52,440.68z M254.37,440.88v7.07h12.19v30.47h7.6V447.9h12.07v-7.03H254.37z M330.59,471.34
      v-30.49h-7.51v37.56h24.21v-7.05h-16.71V471.34z M187.07,440.88v37.49h7.41v-37.49H187.07z M242.66,440.92v37.56h7.36v-37.56
      H242.66z"
      />
      <path
        class="st3"
        d="M392.61,440.88c4.03,0,7.92,0,11.9,0c0,12.53,0,24.91,0,37.49c-2.44,0-4.88,0-7.53,0c0-8.47,0-16.92,0-25.35
      c-0.17-0.02-0.31-0.05-0.48-0.07c-0.27,0.82-0.56,1.62-0.77,2.44c-1.93,7.1-3.89,14.2-5.72,21.32c-0.39,1.45-0.99,1.96-2.44,1.88
      c-2.32-0.1-4.64-0.02-7.1-0.02c-2.37-8.64-4.68-17.14-7-25.66c-0.14,0.02-0.29,0.02-0.43,0.05c0,8.43,0,16.87,0,25.45
      c-2.61,0-4.97,0-7.53,0c0-12.43,0-24.89,0-37.66c3.72,0,7.44-0.07,11.15,0.1c0.46,0.02,1.04,1.3,1.23,2.08
      c2.17,8.21,4.27,16.42,6.42,24.65c0.12,0.48,0.34,0.97,0.68,1.98C387.66,459.61,390.15,450.24,392.61,440.88z"
      />
      <path
        class="st3"
        d="M648.07,472.84c4.2-4.9,5.53-10.65,4.42-16.87c-2.03-11.37-12.55-17.96-24.21-15.38
      c-10.48,2.34-16.87,13.21-14.15,24.07c2.87,11.32,14.75,17.41,26.17,13.28c1.57-0.58,2.44-0.29,3.4,0.89
      c1.5,1.76,3.16,3.38,4.61,4.95c1.47-1.3,2.78-2.49,4.22-3.77c-1.93-2.03-3.72-3.94-5.6-5.89
      C647.4,473.64,647.74,473.23,648.07,472.84z M641.48,468.35c-1.38-1.57-2.73-3.14-4.2-4.85c-1.57,1.47-2.82,2.66-4.22,3.98
      c1.16,1.3,2.2,2.51,3.31,3.77c-5.12,1.83-11.01-0.7-13.42-5.6c-2.82-5.75-1.09-13.18,3.81-16.37c4.8-3.11,11.66-2.17,15.21,2.05
      C645.85,455.92,645.73,463.76,641.48,468.35z"
      />
      <path
        class="st3"
        d="M466.94,440.83c2.66,0,4.88,0,7.31,0c0,12.53,0,24.96,0,37.73c-3.33,0-6.69,0.07-10.04-0.07
      c-0.53-0.02-1.18-1.01-1.47-1.69c-3.38-7.63-6.71-15.26-10.07-22.91c-0.34-0.75-0.68-1.5-1.45-2.2c0,8.84,0,17.7,0,26.7
      c-2.7,0-5.05,0-7.56,0c0-12.48,0-24.91,0-37.66c3.33,0,6.64-0.1,9.92,0.07c0.58,0.02,1.33,1.06,1.64,1.76
      c3.5,7.82,6.9,15.67,10.36,23.51c0.29,0.65,0.6,1.28,1.33,1.86C466.94,458.98,466.94,450,466.94,440.83z"
      />
      <path
        class="st3"
        d="M558.97,458.26c2.58-2.56,4.06-5.29,3.65-8.69c-0.56-4.73-4.42-8.11-10.21-8.5
      c-5.24-0.34-10.53-0.22-15.76-0.29c-0.29,0-0.56,0.17-0.77,0.24v37.47c5.89,0,11.61,0.24,17.31-0.05
      c6.18-0.34,10.43-4.06,11.25-9.27C565.22,464.37,563.19,460.84,558.97,458.26z M543.47,447.88c2.82,0.12,5.5,0.12,8.18,0.46
      c1.76,0.22,3.04,1.33,3.09,3.31c0.05,2.1-1.16,3.31-3.07,3.55c-2.66,0.31-5.36,0.29-8.21,0.43L543.47,447.88L543.47,447.88z
       M553.63,470.88c-3.31,0.41-6.66,0.46-10.14,0.68v-8.91c3.4,0.17,6.61,0.19,9.8,0.56c1.96,0.22,3.26,1.4,3.33,3.6
      C556.72,469,555.8,470.62,553.63,470.88z"
      />
      <path
        class="st3"
        d="M758.11,440.08c-11.39-0.05-19.87,8.26-19.87,19.51c-0.02,11.18,8.33,19.53,19.55,19.55
      c11.32,0.05,19.65-8.16,19.7-19.36C777.54,448.5,769.31,440.15,758.11,440.08z M757.89,471.75c-6.86,0-11.61-5.05-11.59-12.22
      c0.02-7.05,4.83-12.09,11.49-12.14c6.81-0.05,11.59,4.95,11.61,12.17C769.46,466.83,764.77,471.78,757.89,471.75z"
      />
      <path
        class="st3"
        d="M233.8,443.8c-0.89,2.08-1.81,4.2-2.61,6.06c-3.14-0.82-6.06-1.88-9.05-2.27c-4.9-0.65-9.46,0.43-12.07,5.12
      c-2.7,4.83-2.58,9.85,0.43,14.53c2.87,4.47,11.18,6.23,15.72,3.52c0.36-0.22,0.72-0.77,0.75-1.21c0.1-1.47,0.02-2.97,0.02-4.66
      c-2.78,0-5.31,0-8.02,0c0-2.49,0-4.76,0-7.24c5.14,0,10.28,0,15.6,0c0.05,0.8,0.12,1.52,0.12,2.25c0.02,3.91,0,7.82,0,11.73
      c0,4.51,0,4.54-4.37,5.89c-4.88,1.5-9.87,2.17-14.92,1.23c-10.02-1.86-16.42-10.98-15.11-21.32c1.52-12.14,10.91-19,23.51-17.14
      C228.64,441,231.39,441.96,233.8,443.8z"
      />
      <path
        class="st3"
        d="M170.29,442.61c-6.71-2.61-13.71-1.76-20.76-1.86v38.07c5.67-0.29,11.23-0.1,16.59-0.89
      c8.88-1.35,14.65-7.89,15.4-16.44C182.26,452.66,178.16,445.7,170.29,442.61z M172.1,465.69c-3.09,6.01-9.03,5.6-14.92,5.67v-23.3
      c5.12-0.17,10.31-0.36,13.88,4.03C174.46,456.21,174.42,461.18,172.1,465.69z"
      />
      <path
        class="st3"
        d="M804.49,442.95c-6.93-3.04-14.24-2.1-21.58-2.2v38.07c5.7-0.29,11.23-0.1,16.61-0.89
      c8.69-1.33,14.32-7.53,15.33-16.01C815.86,453.48,811.92,446.21,804.49,442.95z M805.09,466.37c-3.31,5.34-8.96,4.92-14.48,5
      v-23.39c5.6,0.07,11.2-0.22,14.53,5.09C807.84,457.34,807.75,462.14,805.09,466.37z"
      />
      <path
        class="st3"
        d="M679.82,463.13c0.36-0.27,0.53-0.39,0.72-0.48c5-2.87,7.05-7.15,6.06-12.63c-0.82-4.59-4.66-8.47-9.87-8.86
      c-5.89-0.43-11.83-0.1-17.99-0.1v37.37h7.56V464.1c0.89,0,1.5,0.1,2.1-0.02c2.49-0.41,3.96,0.58,5.17,2.8
      c1.93,3.57,4.2,6.95,6.37,10.38c0.36,0.56,1.06,1.21,1.64,1.23c2.44,0.12,4.9,0.05,7.8,0.05
      C686.02,473.18,682.91,468.18,679.82,463.13z M675.47,456.86c-2.95,0.39-5.94,0.41-9.13,0.63v-9.68c3.14,0.22,6.2,0.22,9.2,0.7
      c2.29,0.34,3.33,1.96,3.26,4.18C678.73,454.88,677.69,456.54,675.47,456.86z"
      />
      <path
        class="st3"
        d="M506.87,440.83c2.63,0,5,0,7.68,0c0,1.67,0,3.21,0,4.76c0,6.01,0.05,12.05-0.02,18.06
      c-0.07,9.54-5.09,14.85-14.58,15.38c-1.86,0.1-3.77,0.05-5.62-0.17c-7.05-0.87-11.97-5.79-12.24-12.89
      c-0.29-8.11-0.12-16.25-0.12-24.38c0-0.22,0.12-0.41,0.22-0.75c2.37,0,4.73,0,7.31,0c0.05,0.89,0.1,1.69,0.1,2.49
      c0.02,6.86-0.05,13.71,0.07,20.54c0.07,4.32,1.88,6.9,5.38,7.56c2.03,0.39,4.22,0.39,6.25,0c3.67-0.7,5.46-3.48,5.5-8.23
      c0.1-6.54,0.02-13.11,0.02-19.65C506.87,442.76,506.87,441.94,506.87,440.83z"
      />
      <path
        class="st3"
        d="M318.69,476.12c-3.6-9.73-7.19-19.46-10.82-29.19c-0.77-2.03-1.11-4.78-2.61-5.82
      c-1.5-1.04-4.22-0.22-6.37-0.43c-1.74-0.17-2.34,0.65-2.87,2.1c-3.21,8.84-6.47,17.65-9.73,26.43c-1.11,3.02-2.25,6.04-3.45,9.34
      c2.56,0,4.83,0.1,7.05-0.05c0.53-0.05,1.23-0.75,1.45-1.33c0.77-1.79,1.47-3.62,2-5.5c0.39-1.47,1.21-1.81,2.61-1.76
      c3.55,0.07,7.07,0.07,10.62,0c1.33-0.05,2.05,0.34,2.44,1.67c0.58,1.93,1.3,3.84,2.08,5.72c0.22,0.51,0.82,1.16,1.3,1.21
      c2.27,0.12,4.56,0.05,7.1,0.05C319.14,477.5,318.93,476.8,318.69,476.12z M296.62,462.56c1.5-4.2,2.92-8.14,4.56-12.75
      c1.64,4.56,3.09,8.55,4.59,12.75H296.62z"
      />
      <path
        class="st3"
        d="M420.66,471.39c5.55,0,11.01,0,16.63,0c0,2.44,0,4.66,0,7.03c-8.09,0-16.1,0-24.31,0c0-12.43,0-24.89,0-37.52
      c7.99,0,15.98,0,24.17,0c0,2.22,0,4.44,0,7c-5.48,0-10.98,0-16.59,0c0,2.66,0,4.97,0,7.51c5,0,9.92,0,15.06,0c0,2.49,0,4.78,0,7.34
      c-4.95,0-9.85,0-14.97,0C420.66,465.67,420.66,468.32,420.66,471.39z"
      />
      <path
        class="st3"
        d="M828.6,471.39c5.55,0,11.01,0,16.63,0c0,2.44,0,4.66,0,7.03c-8.09,0-16.1,0-24.31,0c0-12.43,0-24.89,0-37.52
      c7.99,0,15.98,0,24.17,0c0,2.22,0,4.44,0,7c-5.48,0-10.98,0-16.59,0c0,2.66,0,4.97,0,7.51c5,0,9.92,0,15.06,0c0,2.49,0,4.78,0,7.34
      c-4.95,0-9.85,0-14.97,0C828.6,465.67,828.6,468.32,828.6,471.39z"
      />
      <path
        class="st3"
        d="M737.25,443.68c-0.97,2.2-1.91,4.32-2.85,6.52c-6.93-3.21-13.81-5.17-19.39,2
      c-3.36,4.32-2.78,11.81,0.65,15.84c3.89,4.59,9.99,4.95,18.69,0.94c0.97,2.17,1.91,4.32,2.85,6.47
      c-7.12,5.62-20.64,4.68-26.99-1.79c-6.74-6.88-7.29-19.17-1.21-26.77C715.16,439.26,727.91,437.74,737.25,443.68z"
      />
      <path
        class="st3"
        d="M585.5,478.44c-2.7,0-5.09,0-7.7,0c0-4.01,0.1-7.92-0.05-11.81c-0.05-1.42-0.43-2.97-1.13-4.2
      c-3.55-6.45-7.24-12.82-10.86-19.24c-0.39-0.7-0.72-1.4-1.28-2.49c2.9,0,5.43-0.1,7.94,0.07c0.6,0.05,1.3,0.89,1.64,1.52
      c2.51,4.61,4.95,9.27,7.58,14.24c2.53-4.76,5-9.2,7.24-13.74c0.82-1.69,1.83-2.27,3.65-2.15c1.93,0.14,3.89,0.02,6.28,0.02
      c-0.51,1.01-0.82,1.71-1.21,2.39c-3.69,6.54-7.44,13.06-11.06,19.63c-0.6,1.11-0.94,2.53-0.99,3.81
      C585.43,470.45,585.5,474.36,585.5,478.44z"
      />
      <path
        class="st3"
        d="M266.56,447.95c-4.3,0-8.18,0-12.19,0c0-2.46,0-4.68,0-7.07c10.6,0,21.15,0,31.84,0c0,2.27,0,4.49,0,7.03
      c-3.96,0-7.87,0-12.05,0c0,10.33,0,20.38,0,30.54c-2.61,0-4.97,0-7.6,0C266.56,468.42,266.56,458.38,266.56,447.95z"
      />
      <path
        class="st3"
        d="M323.06,440.85c2.56,0,4.92,0,7.53,0c0,10.16,0,20.21,0,30.49c5.67,0,11.11,0,16.68,0c0,2.46,0,4.68,0,7.05
      c-8.04,0-16.05,0-24.21,0C323.06,465.96,323.06,453.5,323.06,440.85z"
      />
      <path
        class="st3"
        d="M187.07,440.88c2.61,0,4.97,0,7.44,0c0,12.53,0,24.91,0,37.49c-2.44,0-4.85,0-7.44,0
      C187.07,465.91,187.07,453.48,187.07,440.88z"
      />
      <path
        class="st3"
        d="M250.03,478.49c-2.56,0-4.88,0-7.36,0c0-12.51,0-24.94,0-37.56c2.39,0,4.8,0,7.36,0
      C250.03,453.41,250.03,465.84,250.03,478.49z"
      />
    </g>
  </svg>
</div>


 




<footer>
  <div class="px-3">
    <div class="theme-container">
      <div
        fxLayout="column"
        fxLayout.gt-sm="row wrap"
        fxLayoutAlign="center center"
        class="content border-lighter py-5"
      >
        <div
          fxFlex="100"
          fxFlex.gt-sm="40"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <h1 class="secondary-font">{{ "FOOTER.SUBSCRIBE_TITLE" | translate }}</h1>
          <p class="desc">
            {{ "FOOTER.SUBSCRIBE_DESC" | translate }}
          </p>
        </div>
        <form
          [formGroup]="subscribeForm"
          (ngSubmit)="onSubscribeFormSubmit(subscribeForm.value)"
          class="custom-form"
          fxLayout="row"
          fxFlex="100"
          fxFlex.gt-sm="42.4"
          ngClass.sm="mt-2"
          ngClass.xs="mt-2"
        >
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="subscribe-input"
            fxFlex
          >
            <input
              matInput
              autocomplete="off"
              formControlName="name"
              [placeholder]="'FOOTER.SUBSCRIBE_PLACEHOLDER' | translate"
            />
          </mat-form-field>
          <button
            mat-flat-button
            type="submit"
            class="subscribe-btn"
            color="primary"
          >
          {{ 'FOOTER.SUBSCRIBE_BOTTON' | translate }}
          </button>
        </form>
      </div>
      <div class="py-5 content border-lighter">
        <div fxLayout="row wrap" fxLayoutAlign="space-around">
          <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45">
            <a class="logo" routerLink="/"><app-logo></app-logo></a>
            <p class="mt-4 mb-3 desc">
              {{ 'HEADDER.DESC'  | translate }}
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
              <mat-icon color="primary">location_on</mat-icon>
              <span class="mx-2"
                >{{ 'FOOTER.ADDRESS' | translate }}
                </span
              >
            </p>
            <p
              fxLayout="row"
              fxLayoutAlign="start center"
              (click)="makeCall()"
              class="mt-1 call"
            >
              <mat-icon color="primary">call</mat-icon>
              <span class="mx-2">
                {{ "FOOTER.PHONE" | translate }}</span
              >
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
              <mat-icon color="primary">mail_outline</mat-icon>
              <span class="mx-2"
                >{{ "FOOTER.E-MAIL" | translate }}</span
              >
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
              <mat-icon color="primary">schedule</mat-icon>
              <span class="mx-2">
                {{ 'FOOTER.WORK_HOURSE' | translate }}</span
              >
            </p>
            <app-social-icons
              [iconSize]="'lg'"
              fxLayout="row"
              fxLayoutAlign="start center"
              class="desc"
            ></app-social-icons>
          </div>

          <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45" ngClass.xs="pt-5">
            <h1 class="secondary-font">
              {{ "FOOTER.FEEDBACK_TITLE" | translate }}
            </h1>
            <p class="desc">
              {{ 'FOOTER.FEEDBACK_DESC' | translate }}
            
            </p>
            <form
              [formGroup]="feedbackForm"
              (ngSubmit)="onFeedbackFormSubmit(feedbackForm.value)"
              class="custom-form pt-2"
            >
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'FOOTER.FEEDBACK_E-MAIL' | translate}}</mat-label>
                <input matInput autocomplete="off" formControlName="name" />
                <mat-error *ngIf="feedbackForm.controls.name.errors?.required"
                  >Email is required</mat-error
                >
                <mat-error
                  *ngIf="feedbackForm.controls.name.hasError('invalidEmail')"
                  >Invalid email address</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'FOOTER.FEEDBACK_MESSAGE' | translate}}</mat-label>
                <textarea
                  matInput
                  formControlName="message"
                  rows="6"
                ></textarea>
                <mat-error
                  *ngIf="feedbackForm.controls.message.errors?.required"
                  >Message is required</mat-error
                >
              </mat-form-field>
              <div class="w-100 text-center">
                <button
                  mat-flat-button
                  color="primary"
                  class="uppercase"
                  type="submit"
                >
                {{'FOOTER.FEEDBACK_BOTTON' | translate}}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="space-between center"
        class="copyright py-2"
      >
        <p ngClass.xs="mt-1">{{'FOOTER.Copyright' | translate}}</p>
        <p>
           {{'FOOTER.Designed' | translate}}
          <a
            mat-button
            href="https://oma-marketing.com/"
            color="warn"
            target="_blank"
            >OMA Marketing</a
          >
        </p>
      </div>
    </div>
  </div>
</footer>


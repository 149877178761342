<div class="section">
  <div class="px-3">
    <div class="theme-container">
      <h1 class="section-title">{{ "OUR_SERVICES.TITLE_1" | translate }}</h1>
      <p class="section-desc text-muted">
        {{ "OUR_SERVICES.TITLE_2" | translate }}
      </p>

      <div fxLayout="row wrap"  class="services-wrapper">
        <div fxFlex.xs="100"  fxFlex.sm="50" fxFlex="33" class="p-3">
          <mat-card
            fxLayout="column"
            fxLayoutAlign="start center"
            class="h-100 w-100 text-center p-3 o-hidden mat-card"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="70px"
              viewBox="0 -960 960 960"
              width="70px"
              fill="#9e1c34"
            >
              <path
                d="M160-515.38V-800h284.62v284.62H160Zm30.77-30.77h223.08v-223.08H190.77v223.08ZM160-160v-284.62h284.62V-160H160Zm30.77-30.77h223.08v-223.08H190.77v223.08Zm324.61-324.61V-800H800v284.62H515.38Zm30.77-30.77h223.08v-223.08H546.15v223.08ZM728.77-160v-71.23H800V-160h-71.23ZM515.38-373.15v-71.47h71.24v71.47h-71.24Zm71.24 70.46v-70.46h71.46v70.46h-71.46Zm-71.24 71.46v-71.46h71.24v71.46h-71.24ZM586.62-160v-71.23h71.46V-160h-71.46Zm71.46-71.23v-71.46h70.69v71.46h-70.69Zm0-141.92v-71.47h70.69v71.47h-70.69Zm70.69 70.46v-70.46H800v70.46h-71.23Z"
              />
            </svg>
            <h2 class="secondary-font capitalize fw-600 my-3">
              {{ "OUR_SERVICES.SERVICE_TITLE_1" | translate }}
            </h2>
            <p>
              {{ "OUR_SERVICES.SERVICE_DESC_1" | translate }}
            </p>
          </mat-card>
        </div>

        <div fxFlex.xs="100"  fxFlex.sm="50" fxFlex="33" class="p-3">
          <mat-card
            fxLayout="column"
            fxLayoutAlign="start center"
            class="h-100 w-100 text-center p-3 o-hidden "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="70px"
              viewBox="0 -960 960 960"
              width="70px"
              fill="#9e1c34"
            >
              <path
                d="M80-200v-22h800v22H80Zm106-68q-22.77 0-38.39-15.61Q132-299.23 132-322v-388q0-22.78 15.61-38.39Q163.23-764 186-764h588q22.78 0 38.39 15.61T828-710v388q0 22.77-15.61 38.39Q796.78-268 774-268H186Zm0-22h588q12 0 22-10t10-22v-388q0-12-10-22t-22-10H186q-12 0-22 10t-10 22v388q0 12 10 22t22 10Zm-32 0v-452 452Z"
              />
            </svg>
            <h2 class="secondary-font capitalize fw-600 my-3">
              {{ "OUR_SERVICES.SERVICE_TITLE_2" | translate }}
            </h2>
            <p>
              {{ "OUR_SERVICES.SERVICE_DESC_2" | translate }}
            </p>
          </mat-card>
        </div>
        <div fxFlex.xs="100"  fxFlex.sm="50" fxFlex="33" class="p-3">
          <mat-card
            fxLayout="column"
            fxLayoutAlign="start center"
            class="h-100 w-100 text-center p-3  o-hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="70px"
              viewBox="0 -960 960 960"
              width="70px"
              fill="#9e1c34"
            >
              <path
                d="M256-312q56.07 0 109.04 13.5Q418-285 470-256v-402q-45-35-101.32-52.5Q312.37-728 256-728q-36.99 0-61.5 4-24.5 4-58.5 15-12 4-17 11.5t-5 16.5v362q0 13 10 19.5t22 2.5q17-7 45-11t65-4Zm236 56q51-29 103-42.5T704-312q37 0 65.5 4t44.5 9q12 5 22-1.5t10-20.5v-360q0-9-5-16t-17-12q-33-11-57.82-15T704-728q-57 0-112.5 17.5T492-658v402Zm-11.5 32Q430-256 373-273t-117-17q-25.95 0-50.98 3Q180-284 154-277q-23.1 9-42.55-6.48Q92-298.96 92-325v-352q0-17 9-31.4 9-14.4 25-20.6 31-11 63.86-16 32.85-5 66.14-5 60.91 0 117.96 18Q431-714 481-679q49-35 106-53t117-18q33.29 0 66.14 5Q803-740 834-729q16 6 25 20.5t9 31.5v352q0 25.89-22 40.45Q824-270 800-279q-24-6-48.23-8.5Q727.54-290 704-290q-60.26 0-116.63 17T480.5-224ZM292-500Z"
              />
            </svg>
            <h2 class="secondary-font capitalize fw-600 my-3">
              {{ "OUR_SERVICES.SERVICE_TITLE_3" | translate }}
            </h2>
            <p>{{ "OUR_SERVICES.SERVICE_DESC_3" | translate }}.</p>
          </mat-card>
        </div>
        <div fxFlex.xs="100"  fxFlex.sm="50" fxFlex="33" class="p-3">
          <mat-card
            fxLayout="column"
            fxLayoutAlign="start center"
            class="h-100 w-100 text-center p-3 o-hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="70px"
              viewBox="0 -960 960 960"
              width="70px"
              fill="#9e1c34"
            >
              <path
                d="M132-212v-33h80v-449q0-21.5 16.25-37.75T266-748h522v22H266q-14 0-23 9t-9 23v449h213v33H132Zm456 0q-11.47 0-19.24-7.76Q561-227.52 561-239v-366q0-11.47 7.76-19.24Q576.53-632 588-632h214q11.47 0 19.24 7.76Q829-616.47 829-605v366q0 11.48-7.76 19.24Q813.47-212 802-212H588Zm-5-33h224v-365H583v365Zm0 0h224-224Z"
              />
            </svg>
            <h2 class="secondary-font capitalize fw-600 my-3">
              {{ "OUR_SERVICES.SERVICE_TITLE_4" | translate }}
            </h2>
            <p>
              {{ "OUR_SERVICES.SERVICE_DESC_4" | translate }}
            </p>
          </mat-card>
        </div>

        <div fxFlex.xs="100"  fxFlex.sm="50" fxFlex="33" class="p-3">
          <mat-card
            fxLayout="column"
            fxLayoutAlign="start center"
            class="h-100 w-100 text-center p-3 o-hidden "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="70px"
              viewBox="0 -960 960 960"
              width="70px"
              fill="#9e1c34"
            >
              <path
                d="M253-191q-14.21 0-28.11-1-13.89-1-27.89-6 21-20 27.5-43.5T231-295q0-26.92 18.06-44.96t45-18.04q26.94 0 45.44 18.04Q358-321.92 358-295q0 44.55-30.73 74.27Q296.55-191 253-191Zm0-22q33 0 58-23.82 25-23.83 25-58.18 0-17-12-29t-30.21-12q-18.21 0-29.5 12T253-295q0 28-6.5 47T227-216q4 1 12.5 2t13.5 1Zm184-171-50-52 323-323q11-12 28-12.5t30 12.5l-7-8q12 13 12 29.5T761-708L437-384Zm-143 89Z"
              />
            </svg>
            <h2 class="secondary-font capitalize fw-600 my-3">
              {{ "OUR_SERVICES.SERVICE_TITLE_5" | translate }}
            </h2>
            <p>
              {{ "OUR_SERVICES.SERVICE_DESC_5" | translate }}
            </p>
          </mat-card>
        </div>
        <div fxFlex.xs="100"  fxFlex.sm="50" fxFlex="33" class="p-3">
          <mat-card
            fxLayout="column"
            fxLayoutAlign="start center"
            class="h-100 w-100 text-center p-3 o-hidden "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="70px"
              viewBox="0 -960 960 960"
              width="70px"
              fill="#9e1c34"
            >
              <path
                d="M251-212.5q-36.59-38.5-57.8-87.72Q172-349.44 172-401q0-56 21-109t65-97q39-39.41 100-60.21Q419-688 487-697t136-9.5q68-.5 122 1.5 3 55 2.5 123.5t-10 136.25q-9.5 67.75-31 128.06-21.5 60.32-59.9 99Q604-174 552.5-153q-51.5 21-104.83 21Q393-132 341-152.5t-90-60Zm51 13.5q31 22 70 33.5t75.64 11.5q49.04 0 96.2-19.5Q591-193 631-234q20.81-21 39.12-57t31.17-90.5q12.86-54.5 19.28-129Q727-585 724-684q-62-2-129 .5t-128 13q-61 10.5-111 29T276-593q-43 43-62.5 93T194-407q0 50 21 100.5t60 83.5q22-74 71.5-138T488-483q-72 63-117 129.5T302-199Zm0 0Zm0 0Z"
              />
            </svg>
            <h2 class="secondary-font capitalize fw-600 my-3">
              {{ "OUR_SERVICES.SERVICE_TITLE_6" | translate }}
            </h2>
            <p>
              {{ "OUR_SERVICES.SERVICE_DESC_6" | translate }}
            </p>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-toolbar
  id="top-toolbar"
  class="top-toolbar"
  

>
  <div
    class="theme-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <app-contacts fxLayout="row" fxLayoutAlign="start center"></app-contacts>
    </div>
    <app-social-icons
      fxLayout="row"
      fxLayoutAlign="start center"
 
    ></app-social-icons>
  </div>
</mat-toolbar>
<mat-toolbar id="main-toolbar" class="mat-elevation-z2">
  <div
    class="theme-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <span fxHide="false" fxHide.gt-sm>
      <button mat-icon-button (click)="sidenavToggle()" class="p-0">
        <mat-icon class="mat-icon-lg m-0">menu</mat-icon>
      </button>
    </span>
    <a class="logo" routerLink="/"><app-logo></app-logo></a>
    <div fxShow="false" fxShow.gt-sm class="horizontal-menu">
      <app-horizontal-menu [menuParentId]="0"></app-horizontal-menu>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <app-lang></app-lang>
    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container id="1001">
  <mat-sidenav
    [opened]="false"
    mode="over"
    #sidenav
    class="sidenav mat-elevation-z6"
  >
    <button
      mat-icon-button
      color="warn"
      class="close"
      (click)="sidenav.close()"
    >
      <mat-icon color="warn">close</mat-icon>
    </button>
    <div class="divider"></div>
    <div class="vertical-menu-wrapper">
      <ng-scrollbar pointerEventsMethod="scrollbar">
        <app-vertical-menu [menuParentId]="0"></app-vertical-menu>
      </ng-scrollbar>
    </div>
    <div class="divider"></div>
    <app-social-icons
      [iconSize]="'lg'"
      [iconColor]="'primary'"
      fxLayout="row"
      fxLayoutAlign="center center"
    ></app-social-icons>
  </mat-sidenav>

  <mat-sidenav-content
    class="page-wrapper"
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <header
      [ngClass]="'toolbar-' + settings.toolbar"
      [class.has-bg-image]="settings.headerBgImage"
      [class.has-bg-video]="settings.headerBgVideo"
      [class.main-toolbar-fixed]="settings.mainToolbarFixed"
    >
      <app-toolbar1
        *ngIf="settings.toolbar == 1"
        (onMenuIconClick)="sidenav.toggle()"
      ></app-toolbar1>
    </header>

    <main [class.content-offset-to-top]="settings.contentOffsetToTop">
      <router-outlet></router-outlet>
    </main>
    <div class="sticky-footer-helper" fxFlex="grow"></div>
    <app-footer class="scroll-margin-top" id="5005"></app-footer>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="options-icon"
      (click)="options.toggle()"
    >
      <mat-icon>settings</mat-icon>
    </div>

    <div
      *ngIf="showBackToTop"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="back-to-top"
      (click)="scrollToTop()"
    >
      <mat-icon>arrow_upward</mat-icon>
    </div>
  </mat-sidenav-content>

  <mat-sidenav #options position="end" class="options" autoFocus="false">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="op-header mat-elevation-z1"
    >
      <h2>Settings</h2>
      <button mat-icon-button (click)="options.toggle()" color="accent">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ng-scrollbar pointerEventsMethod="scrollbar">
      <div fxLayout="column" class="control">
        <h4>Layout</h4>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>Sticky Menu Toolbar</span>
          <mat-slide-toggle
            [checked]="settings.stickyMenuToolbar"
            (change)="settings.stickyMenuToolbar = !settings.stickyMenuToolbar"
            labelPosition="before"
          ></mat-slide-toggle>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>RTL</span>
          <mat-slide-toggle
            [checked]="settings.rtl"
            (change)="settings.rtl = !settings.rtl"
            labelPosition="before"
          ></mat-slide-toggle>
        </div>
      </div>

      <div fxLayout="column" class="control">
        <h4>Choose header type</h4>
        <mat-radio-group
          [(ngModel)]="headerTypeOption"
          (change)="chooseHeaderType()"
        >
          <mat-radio-button
            *ngFor="let headerType of headerTypes"
            [value]="headerType"
            >{{ headerType }}</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div fxLayout="column" class="control">
        <h4>Choose theme skin</h4>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="skin-primary red" (click)="changeTheme('red')"></div>
          <div class="skin-primary blue" (click)="changeTheme('blue')"></div>
          <div class="skin-primary green" (click)="changeTheme('green')"></div>
          <div class="skin-primary pink" (click)="changeTheme('pink')"></div>
          <div
            class="skin-primary purple"
            (click)="changeTheme('purple')"
          ></div>
          <div class="skin-primary grey" (click)="changeTheme('grey')"></div>
          <div
            class="skin-primary orange-dark"
            (click)="changeTheme('orange-dark')"
          >
            <div class="skin-secondary"></div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </mat-sidenav>
</mat-sidenav-container>
